<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Printing
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Printing
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="showPrintDialog()" class="mt-4 btn btn-primary  mr-1" dark>
                  <i class="fa fa-cog"></i>
                  Printing URL setup
                </v-btn>
                <v-btn
                  @click="downloadSoftware"
                  class="mt-4 btn btn-primary  mr-1" dark>
                  <i class="fa fa-download"></i>
                  Download print software
                </v-btn>

              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="search-wrapper border-outline px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-select label="What do you want to print?" v-model="print_type" :items="print_types" item-text="name"
                    item-value="value" outlined dense clearable></v-select>
                </v-col>

              </div>
            </div>

            <admit-card-printing v-if="print_type == 'admit_card'"></admit-card-printing>
            <identity-card-printing v-if="print_type == 'id_card'"></identity-card-printing>
            <result-certificate-printing v-if="print_type == 'result_certificate'"></result-certificate-printing>



          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" max-width="700">
        <v-card>
          <v-toolbar dark>
            <v-card-title>
              Printers
            </v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon @click="closePrintDialog">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete :items="printers" v-model="selectedPrinter" label="Select printer" return-object
                  item-text="title" outlined dense></v-autocomplete>

              </v-col>

              <v-col cols="12">
                <span v-if="selectedPrinter">
                  Printing URL: <a href="#" class="font-weight-bold">{{ selectedPrinter.bulk_printing_url }}</a>
                </span>
              </v-col>

              <v-col cols="12" class="text-right">
                <v-btn class="cancel-btn" depressed @click="closePrintDialog">Cancel
                </v-btn>

              </v-col>
            </v-row>


          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </v-app>
</template>

<script>
import AdmitCardPrinting from "./admit-card/AdmitCardPrinting";
import IdentityCardPrinting from "./identity-card/IdentityCardPrinting";
import ResultCertificatePrinting from "./result-certificate/ResultCertificatePrinting";
import { required } from "vuelidate/lib/validators";
import PrinterService from "@/core/services/printer/PrinterService";
const printerService = new PrinterService();

export default {
  validations: {
    candidate_ids: { required }
  },
  components: {
    AdmitCardPrinting,
    IdentityCardPrinting,
    ResultCertificatePrinting
  },
  data() {
    return {
      loading: false,
      print_type: '',

      print_types: [
        { name: 'ID card', value: 'id_card' },
        { name: 'Result certificate', value: 'result_certificate' },
        { name: 'Admit card', value: 'admit_card' },
      ],
      printing_statuses: [
        { name: 'Pending', value: 0 },
        { name: 'Printed', value: 1 },
      ],
      dialog: false,
      printers: [],
      selectedPrinter: null,
    }
  },
  methods: {

    downloadSoftware() {
      window.open('https://s3.ap-northeast-1.wasabisys.com/yeldemy/uploads/printing-software/Install Yeldemy - Bulk Printing Software v1.20.exe', '_blank')
    },
   getPrinters() {
      printerService.paginate().then(response => {
        this.printers = response.data.data;
      });
    },

    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;

    },
    showPrintDialog() {
      this.dialog = true;
    },

    closePrintDialog() {
      this.dialog = false;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getPrinters();
    this.appUrl = process.env.VUE_APP_URL;

  }
}
</script>
