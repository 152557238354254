<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field class="form-control" v-model="search.name" label="Name, email, phone, symbol No." outlined
                    v-on:keyup.enter="getUsers()" dense>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete :items="academicYears" class="form-control" v-model="search.academic_year_id"
                    label="Academic year" item-value="id" item-text="year" outlined v-on:keyup.enter="getUsers()"
                    @input="search.academic_year_id = $event !== null ? $event : ''" dense>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete :items="levels" class="form-control" v-model="search.level_id" label="Level" :error="$v.search.level_id.$error"
                    item-value="id" item-text="title" outlined v-on:keyup.enter="getUsers()" @change="getPrograms"
                    @input="search.level_id = $event !== null ? $event : ''" dense>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete outlined dense class="form-control" v-model="search.program_id" :items="programs" :error="$v.search.program_id.$error"
                    label="Programs" item-value="id" :loading="programLoading" item-text="title"
                    v-on:keyup.enter="getUsers()" @input="search.program_id = $event !== null ? $event : ''"
                    @change="getGrades">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete outlined dense class="form-control" v-model="search.grade_id" :items="gradesLevels"
                    :loading="gradeLoading" label="Semester/year" item-value="id" item-text="title"
                    v-on:keyup.enter="getUsers()" @input="search.grade_id = $event !== null ? $event : ''"
                    @change="getAcademicClasses" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete outlined dense class="form-control" v-model="search.class_id" :items="academic_classes"
                    label="Class name" item-value="id" item-text="title" :loading="classLoading"
                    @input="search.class_id = $event !== null ? $event : ''" clearable
                    v-on:keyup.enter="getUsers()"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete outlined dense class="form-control" v-model="search.type" :items="user_type"
                    label="User type" item-value="value" item-text="name" v-on:keyup.enter="getUsers()"
                    @input="search.type = $event !== null ? $event : ''" clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete outlined dense class="form-control" v-model="search.id_type" :items="printOption"
                    label="Type" item-value="value" item-text="name"
                    @input="search.id_type = $event !== null ? $event : ''" v-on:keyup.enter="getUsers()"
                    clearable></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn @click.prevent="searchUsers()" class="btn btn-primary btn-block w-35 float-right" :loading="loading"
                    dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <v-col cols="12" v-if="selected.length > 0">
              <v-card flat color="grey lighten-4">
                <v-toolbar flat color="grey lighten-4">
                  <v-toolbar-title>Total selected : {{ selected.length }}</v-toolbar-title>
                  <v-toolbar-title class="ml-5"> Actions:
                  </v-toolbar-title>
                  <v-btn depressed @click="addStudents" class="ml-2">
                    <v-icon>assignment_ind</v-icon>
                    Add to selection list
                  </v-btn>

                  <v-btn depressed @click="dialog = true;" class="ml-2">
                    <v-icon>assignment_ind</v-icon>
                    Prepare ID cards
                  </v-btn>

<!--                  <v-btn depressed @click="previewIdCard" class="ml-2">-->
<!--                    <v-icon>assignment_ind</v-icon>-->
<!--                    Preview selected-->
<!--                  </v-btn>-->

                </v-toolbar>
              </v-card>

            </v-col>
            <!--new design-->

            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="studentList.length > 0">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Image</th>
                    <th class="px-3 wrap-column">Name</th>
                    <th class="px-3 wrap-column" v-if="search.id_type == 'printed'">Print status</th>
                    <th class="px-3 wrap-column">More info</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) of studentList" :key="index">
                    <td class="wrap-column px-3">
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.lenght > 0" :src="user.image_path.thumb"
                          class="cursor-pointer" alt="" @click="changeImage(user)" style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                          <span class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                            @click="changeImage(user)"> {{
                              user.first_name.charAt(0)
                            }} </span>
                        </span>
                      </div>
                    </td>
                    <td>

                      <router-link v-if="user.type"
                        :to="{ name: 'students-summary', params: { id: user.id, type: user.type[0] } }">
                        <a href="#" class="navi-link">
                          {{ user.full_name ? user.full_name : '' }}
                        </a>
                        &nbsp;&nbsp;<i
                          :class="user.is_active ? 'fas fa-circle dot-active' : 'fas fa-circle dot-inactive'"></i>


                      </router-link>

                      <span class="text-secondary">
                        <br />
                        <b> CRN No.: </b>{{ user.personal_id }}<br />
                        <b v-if="user.setting && user.setting.midas_id">External ID:</b> {{ user.setting.midas_id }} <br
                          v-if="user.setting && user.setting.midas_id">
                        <b v-if="user.setting && user.setting.symbol_no">Symbol No.:</b> {{ user.setting.symbol_no ?
                          user.setting.symbol_no : 'NA' }}<br />
                      </span>

                    </td>
                    <td class="px-3 wrap-column" v-if="search.id_type === 'printed'">
                      <span class="badge badge-success">Printed</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <div>
                        <strong>Program: </strong>
                        {{user.setting ? user.setting.program_title : "N/A"}}
                      </div>
                      <div class="mt-2">
                        <strong>Semester/Year: </strong>
                        {{user.setting ? user.setting.current_semester_title: "N/A"}}
                      </div>
                      <div class="mt-2">
                        <strong>Class: </strong>
                        {{user.setting ? user.setting.current_class_title :"N/A"}}
                      </div>
                    </td>
                    <td class="px-3 wrap-column">
                      <span :class="user.is_active ? 'badge badge-success' : 'badge badge-danger'">{{
                        user.is_active ? 'Active' : 'Inactive'
                      }}</span>
                    </td>
                    <td class="px-3 wrap-column">

                      <v-btn class="navi-link" text @click="removeStudentFromList(index)">
                        <!--                                                            <span class="navi-icon">-->
                        <!--                                                              <i class="flaticon-edit"></i>-->
                        <!--                                                            </span>-->
                        <span class="navi-text red--text">Remove</span>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table" v-if="users.length > 0 && !loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">
                      <v-checkbox class="id-card__checkbox" @change="selectAll()" v-model="checkAll"></v-checkbox>
                    </th>
                    <th class="px-3 wrap-column">Image</th>
                    <th class="px-3 wrap-column">Full name</th>
                    <th class="px-3 wrap-column" v-if="search.id_type == 'printed' || search.id_type == 'ready'">Print status</th>
                    <th class="px-3 wrap-column">More info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) of users" :key="index">
                    <td class="px-3">
                      <v-checkbox v-model="selected" :value="user"></v-checkbox>
                    </td>
                    <td class="px-3 wrap-column">
                      <div class="symbol-label">
                        <img v-if="user.image_path && user.image_path.real" :src="user.image_path.real"
                          class="cursor-pointer" alt="" @click="changeImage(user)" style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                          <span class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                            @click="changeImage(user)">
                            {{ (user.full_name) ? user.full_name.charAt(0) : null }}
                          </span>
                        </span>
                      </div>
                    </td>
                    <td class="px-3 wrap-column">
                      <router-link v-if="user.type"
                        :to="{ name: 'students-summary', params: { id: user.id, type: user.type[0] } }">
                        <a href="#" class="navi-link">
                          {{ user.full_name ? user.full_name : '' }}
                        </a>
                        &nbsp;&nbsp;<i
                          :class="user.is_active ? 'fas fa-circle dot-active' : 'fas fa-circle dot-inactive'"></i>


                      </router-link>

                      <span class="text-secondary">
                        <br />
                        <b> CRN No.: </b>{{ user.personal_id }}<br />
                        <b>External ID:</b> {{ user.setting && user.setting.midas_id ? user.setting.midas_id : 'N/A' }}
                        <br>
                        <b>Symbol No.:</b> {{ user.setting && user.setting.symbol_no ? user.setting.symbol_no :
                          'NA' }}<br />
                      </span>
                    </td>

                    <td class="px-3 wrap-column" v-if="search.id_type == 'printed' || search.id_type == 'ready'">
                      <span class="badge badge-success" v-if="search.id_type === 'printed'">Printed</span>
                      <span class="badge badge-success" v-if="search.id_type === 'ready'">File ready</span>
                    </td>

                    <td class="px-3 wrap-column">
                      <div>
                        <strong>Program: </strong> {{user.setting ? user.setting.program_title : "N/A"}}
                      </div>

                      <div class="mt-2">
                        <strong>Semester/Year: </strong> {{user.setting ? user.setting.current_semester_title: "N/A"}}
                      </div>

                      <div class="mt-2">
                        <strong>Class: </strong> {{ user.setting ? user.setting.current_class_title : "N/A" }}
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              <v-dialog v-model="uploadDialog" max-width="600">
                <v-card>
                  <v-card-title>
                    Change Image
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="4" v-if="user">
                        <div class="symbol-label">
                          <img v-if="user.photo" :src="user.image_path.thumb" class="" alt="" style="height: 150px;">
                          <span v-else class="symbol  symbol-light-success ">
                            <span class="symbol-label font-size-h5 font-weight-bold p-5">Upload Image</span>
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="8'">
                        <v-file-input :rules="rules" v-model="user.photo" outlined dense
                          accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                          label="Profile image"></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <div class="float-right">
                          <v-btn @click="uploadDialog = false"
                            class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                            depressed>Cancel
                          </v-btn>
                          <v-btn class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                            depressed @click="updateStudent" :loading="isBusy">Upload & Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                  </v-card-text>

                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog" max-width="600">
                <v-card>
                  <v-toolbar dark>
                    <v-card-title>
                      ID card print settings
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="dialog = false">
                        <i class="fas fa-times"></i>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="new_id_card.file_name" label="File name" outlined dense></v-text-field>
                        <span class="text-danger" v-if="$v.new_id_card.file_name.$error">This information is
                          required</span>
                      </v-col>
                      <v-col cols="6">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense outlined v-model="expiryDate" v-bind="attrs" v-on="on"
                              label="Card expiry (Date)" class="form-control">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="expiryDate" @input="menu = false; convertAdToBs()"
                            header-menu="primary"></v-date-picker>
                        </v-menu>

                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="dob_bs" label="Card expiry (BS)" prepend-icon="mdi-calendar" outlined dense
                          readonly></v-text-field>

                      </v-col>
                      <v-col cols="12">
                        <v-select v-model="new_id_card.print_id" :items="printers" item-value="id" item-text="title"
                          label="Select printer" outlined dense clearable></v-select>
                        <span class="text-danger" v-if="$v.new_id_card.print_id.$error">Printer is required.</span>
                      </v-col>

                      <v-col cols="12" v-if="domainName != 'kist'">
                        <v-switch v-model="new_id_card.is_back_view"
                                  label="Is back view" outlined dense clearable></v-switch>
                      </v-col>
                    </v-row>

                  </v-card-text>
                  <V-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="cancel-btn" depressed large @click="dialog = false">Cancel
                    </v-btn>

                    <v-btn :loading="loading" depressed outlined @click="saveIds()"
                           class="text-white ml-2 btn btn-primary">
                      Save setting & send to prepare PDF
                    </v-btn>
                  </V-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import IdCardService from "@/core/services/IdCard/IdCardService";
import PrinterService from "@/core/services/printer/PrinterService";
import {required, requiredIf} from "vuelidate/lib/validators";
import { API_URL } from "@/core/config";

const idCardService = new IdCardService();
const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()

const printerService = new PrinterService();
export default {
  name: "id-cards-printing",
  validations: {
    new_id_card: {
      print_id: { required },
      file_name: { required },
    },
    search: {
      level_id: {required:requiredIf(function () {
          return this.search.type == 'student';
        })
      },
      program_id: {required:requiredIf(function () {
          return this.search.type == 'student';
        })
      },
    }
  },
  data() {
    return {
      import_excel: false,
      dob_bs: null,
      checkAll: false,
      isLoading: false,
      attrs: false,
      loading: false,
      dialog: false,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      excel_file: null,
      isBusy: false,
      uploadDialog: false,
      new_id_card: {
        printer_id: null,
        file_name: null,
        print_id: null,
        is_back_view: false,
      },
      studentCards: [],
      printOption: [
        {
          name: "New",
          value: 'new'
        },
        {
          name: 'Printed',
          value: 'printed'
        },
        {
          name: 'Print ready',
          value: 'ready',
        }
      ],
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      expiryDate: new Date().toISOString().substr(0, 10),
      user: {
        photo: null,
      },
      blob: null,
      idCard: {
        expiryDate: null,
        studentIds: [],

      },
      user_type: [
        { name: 'Teacher', value: 'teacher' },
        { name: 'Student', value: 'student' },
        { name: 'Staff', value: 'staff' },
      ],
      search: {
        type: 'student',
        id_type: 'new',
        limit: 80,

      },
      users: [],
      selected: [],
      studentList: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      printers: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      perPage: null,
      programLoading: false,
      gradeLoading: false,
      classLoading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    domainName() {
      let origin = window.location.origin;
      if(origin.includes('kist'))
        return 'kist';
      return 'default';
    }
  },
  mounted() {
    if (this.$route.query.first_name) {
      this.search = this.$route.query;
    }

    this.getLevels();
    // this.getPreview();
    this.getAcademicYears();
    this.getPrinters();
    this.convertAdToBs();
  },
  methods: {
    searchUsers() {
      this.$v.search.$touch()
      if (this.$v.search.$error) {
        setTimeout(()=>{
          this.$v.search.$reset()
        },3000)
      } else {
        this.getUsers();
      }
    },
    getPrinters() {
      printerService.paginate().then(response => {
        this.printers = response.data.data;
      });
    },
    getPreview() {
      idCardService.printPreview().then(response => {
        this.blob = 'data:image/png;base64,' + response.data;
      });
    },
    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'student',
        limit: 80
      }
      this.users = [];
      this.loading = false
      // this.getUsers();


    },
    changeImage(user) {
      this.user = user;
      this.uploadDialog = true;
    },
    addStudents() {
      this.selected.forEach((student) => {
        if (this.studentList.indexOf(student) === -1) {
          this.studentList.push(student);
        }
      })

    },
    removeStudentFromList(index) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.studentList.splice(index, 1);
          }
        }
      });

    },
    saveIds() {
      this.$v.new_id_card.$touch();
      if (this.$v.new_id_card.$error) {
        setTimeout(() => {
          this.$v.new_id_card.$reset();
        }, 3000);
      } else {
        this.loading = true
        if (this.studentList.length > 0) {
          this.studentList.forEach((student) => {
            this.studentCards.push({
              user_id: student.id,
              expiry_date: this.expiryDate,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.new_id_card.print_id,
              file_name: this.new_id_card.file_name,
              program_id: this.search.program_id,
              is_back_view: this.new_id_card.is_back_view ? 1 : 0,
              print_types: this.search.type,
            });
          })
        } else {
          this.selected.forEach((student) => {
            this.studentCards.push({
              user_id: student.id,
              expiry_date: this.expiryDate,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.new_id_card.print_id,
              file_name: this.new_id_card.file_name,
              program_id: this.search.program_id,
              is_back_view: this.new_id_card.is_back_view ? 1 : 0,
              print_types: this.search.type,
            });
          })
        }
        idCardService.store(this.studentCards).then((res) => {
          this.studentList = [];
          this.studentCards = [];
          this.selected = [];
          this.isBusy = false;
          this.$bus.emit('checkNotification',);
          this.displayMessage("success")
          this.new_id_card.print_id = '';
          this.new_id_card.file_name = '';
          this.new_id_card.is_back_view = false;
          this.expiryDate = '';
          this.dialog = false;
          this.loading = false
          this.$v.new_id_card.$reset();
          this.getUsers();
        }).catch(error => {
          this.displayMessage("error")
        })
      }


    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user);
        })

      }
    },
    updateStudent() {
      delete this.user.setting
      let fd = this.generateFd();
      this.isBusy = true;
      // delete this.user.email;
      // delete this.user.phone;
      userService
        .update(this.user.id, fd)
        .then(response => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.uploadDialog = false;
          this.getUsers();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    convertAdToBs() {
      let data = {
        dob: this.expiryDate,
      };
      idCardService
        .convertAdToBs(data)
        .then(response => {
          this.dob_bs = response.data.dob;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
          key == "photo" &&
          this.user["photo"] &&
          this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    },
    getUsers() {
      this.loading = true
      idCardService.paginate(this.search, this.page).then(response => {
        // this.$bus.emit('toggleLoader');
        this.selected = [];
        this.checkAll = false
        this.users = response.data.data;
        this.loading = false
      }).catch(error => {
        this.loading = false
      });

    },
    editUser(id) {
      return this.$router.push({ name: "students-update", params: { id: id } });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      this.programLoading = true;
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
        this.programLoading = false;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      this.gradeLoading = true;
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
        this.gradeLoading = false;

      });
    },
    getAcademicClasses() {
      this.classLoading = true;
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
        this.classLoading = false;
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
              .delete(id)
              .then(response => {
                this.$snotify.success("Information deleted");
                this.getSlider();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    },
    manageUserRole(id) {
      this.$router.push({ name: "user-role", params: { id, id } });
    },
    importStudent() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      userService.importUser(fd).then(res => {
        this.isLoading = false
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("Information imported")
        this.getUsers();
      })
    }, previewIdCard() {
      let ids = [];
      if (this.studentList && this.studentList.length > 0) {
        this.studentList.forEach(ele => {
          ids.push(ele.id)
        })
      }
      if (ids.length == 0 && this.selected.length > 0) {
        this.selected.forEach(ele => {
          ids.push(ele.id)
        })
      }
      if (ids && ids.length > 0) {
        let url = ''
        // if (this.search.type != 'student')
        //      url = API_URL + "id-card-teacher"
        //   else
        url = API_URL + "user/preview/id-card"

        if (ids.length > 0) {
          url = url + "?program_id="+this.search.program_id+"&ids=" + ids
          window.open(url)
        }
        return;
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
<style>
.id-card__checkbox {
  padding-top: 0px !important;
}

.id-card__checkbox .v-messages {
  display: none;
}

.id-card__checkbox .v-input__slot {
  margin-bottom: 0px;
  background-color: transparent !important;
}
</style>
