import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AdmitCardService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/admit-card`;

      }
      all() {
            let url = `${this.#api}-all`;
            return apiService.get(url);
      }


      paginate(data,index=1) {


            let url = `${this.#api}`
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
                  if (index)
                        url = url + "&page=" + index
            } else {
                  url = url + "?page=" + index
            }

            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }
      convertAdToBs(data) {
            let url = `${API_URL}user/convert-ad-to-bs`;
            return apiService.post(url, data);
      }
      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }
      printPreview() {
            let url = `${API_URL}user/print-test`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

}
