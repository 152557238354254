<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0 border-outline">
        <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="row" v-if="import_excel">
                <div class="col-10">
                  <v-file-input outlined dense v-model="excel_file" accept=".xlsx, .xls, .csv"
                    label="File input"></v-file-input>
                </div>
                <div class="col-2">
                  <v-btn @click="importStudent" :loading="isLoading" :disabled="!excel_file"
                    class="btn btn-primary ml-2 text-white">Import
                  </v-btn>
                </div>
              </div>
              <v-row class="mt-1">
                <v-col cols="12" class="">
                  <v-form id="kt_password_change_form" class="row bg-filter">
                    <div class="form-group row">
                      <v-col cols="12" md="4">
                        <v-text-field class="form-control" v-model="search.name" label="Name, email, phone, symbol no"
                          outlined dense>
                        </v-text-field>
                        <!--                          <span class="text-danger" v-if="$v.search.name.$error">Name field is required</span>-->
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete :items="academicYears" class="form-control" v-model="search.academic_year_id"
                          label="Academic year" item-value="id" item-text="year" outlined
                          :error="$v.search.academic_year_id.$error"
                          @input="search.academic_year_id = $event !== null ? $event : ''" clearable dense>
                        </v-autocomplete>
                        <span class="text-danger" v-if="$v.search.academic_year_id.$error">This information is
                          required</span>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete :items="levels" class="form-control" v-model="search.level_id" label="Level"
                          item-value="id" item-text="title" outlined @change="getPrograms"
                          :error="$v.search.level_id.$error" @input="search.level_id = $event !== null ? $event : ''"
                          clearable dense>
                        </v-autocomplete>
                        <span class="text-danger" v-if="$v.search.level_id.$error">This information is required</span>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete outlined dense class="form-control" v-model="search.program_id" :items="programs"
                          label="Programs" item-value="id" item-text="title" @change="getGrades"
                          :loading="isProgramLoading" :error="$v.search.program_id.$error"
                          @input="search.program_id = $event !== null ? $event : ''" clearable>
                        </v-autocomplete>
                        <span class="text-danger" v-if="$v.search.program_id.$error">This information is required</span>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete outlined dense class="form-control" v-model="search.grade_id"
                          :items="gradesLevels" label="Semester/year" item-value="id" item-text="title"
                          :error="$v.search.grade_id.$error" @change="getAcademicClasses" :loading="isGradeLoading"
                          @input="search.grade_id = $event !== null ? $event : ''" clearable></v-autocomplete>
                        <span class="text-danger" v-if="$v.search.grade_id.$error">This information is required</span>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete outlined dense class="form-control" v-model="search.class_id"
                          :items="academic_classes" label="Class name" item-value="id" item-text="title"
                          @change="getExams" :loading="isClassLoading" :error="$v.search.class_id.$error"
                          @input="search.class_id = $event !== null ? $event : ''" clearable></v-autocomplete>
                        <span class="text-danger" v-if="$v.search.class_id.$error">This information is required</span>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete outlined dense class="form-control" v-model="search.exam_id" :items="exams"
                          label="Exam" item-value="id" item-text="title" :loading="isExamLoading"
                          :error="$v.search.exam_id.$error" @input="search.exam_id = $event !== null ? $event : ''"
                          clearable></v-autocomplete>
                        <span class="text-danger" v-if="$v.search.exam_id.$error">This information is required</span>
                      </v-col>
                      <!--                                                <div class="col-lg-2">-->
                      <!--                                                    <v-select-->
                      <!--                                                            outlined-->
                      <!--                                                            dense-->
                      <!--                                                            class="form-control"-->
                      <!--                                                            v-model="search.result_certificate"-->
                      <!--                                                            :items="printOption"-->
                      <!--                                                            label="Type"-->
                      <!--                                                            item-value="value"-->
                      <!--                                                            item-text="name"-->
                      <!--                                                            @change="getUsers"-->
                      <!--                                                    ></v-select>-->

                      <!--                                                </div>-->

                      <v-col cols="3"></v-col>

                      <v-col cols="12" md="5" class="text-right">
                        <v-btn @click.prevent="getUsers" type="submit" class=" btn btn-primary  " name="search"
                          ref="search">
                          <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                          Search
                        </v-btn>
                        <v-btn
                          class="text-gray btn btn-standard ml-2"
                          depressed
                          @click.prevent="resetFilter"><i
                            class="fas fa-redo-alt"></i>Reset
                        </v-btn>
                      </v-col>


                    </div>
                  </v-form>
                  <div class="col-12 text-left" v-if="studentList.length > 0">
                    <h6 class="mt-2">Selected users </h6>
                    <table class="table table-stripe">
                      <thead>
                        <th>Name</th>
                        <th v-if="search.id_type == 'printed'">Print status</th>

                        <th>Program</th>
                        <th>Semester/year</th>
                        <th>Class</th>
                        <th>Action</th>
                      </thead>
                      <tbody>
                        <tr v-for="(user, index) of studentList" :key="index">

                          <td>
                            <router-link
                              :to="{ name: 'students-summary', params: { id: user.student_id, type: 'student' } }">
                              <a href="#" class="navi-link">
                                {{ user.user_full_name ? user.user_full_name : '' }}
                              </a>

                              <span class="text-secondary">
                                <br />
                                <b> CRN No.: </b>{{ user.crn }}<br />
                                <b v-if="user.setting.midas_id">External ID:</b> {{ user.setting.midas_id }} <br
                                  v-if="user.setting.midas_id">
                                <b v-if="user.setting.symbol_no">Symbol No.:</b> {{ user.setting.symbol_no ?
                                  user.setting.symbol_no : 'NA' }}<br />
                              </span>

                            </router-link>
                          </td>
                          <td v-if="search.id_type === 'printed'">
                            <span class="badge badge-success">PRINTED</span>
                          </td>

                          <td>{{
                            user.setting ? user.setting.program_title : "N/A"
                          }}
                          </td>
                          <td>{{
                            user.setting ? user.setting.current_semester_title
                            : "N/A"
                          }}
                          </td>
                          <td>{{
                            user.setting ? user.setting.current_class_title :
                            "N/A"
                          }}
                          </td>
                          <td>

                            <v-btn class="navi-link" text @click="removeStudentFromList(index)">
                              <!--                                                            <span class="navi-icon">-->
                              <!--                                                              <i class="flaticon-edit"></i>-->
                              <!--                                                            </span>-->
                              <span class="navi-text red--text">Remove</span>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <v-col cols="12" v-if="selected.length > 0">
                    <v-card flat color="grey lighten-4">
                      <v-toolbar flat color="grey lighten-4">
                        <v-toolbar-title>Total selected: {{ selected.length }}</v-toolbar-title>
                        <v-toolbar-title class="ml-5"> Actions:</v-toolbar-title>
                        <v-btn depressed @click="addStudents" class="ml-2">
                          <v-icon>assignment_ind</v-icon>
                          Add to selection list
                        </v-btn>

                        <v-btn depressed class="ml-2" @click="openDialog">
                          <v-icon>assignment_ind</v-icon>
                          Prepare result certificate
                        </v-btn>
                        <!--                                            <v-btn depressed @click="previewIdCard">-->
                        <!--                                                <v-icon>assignment_ind</v-icon>-->
                        <!--                                                Preview Selected-->
                        <!--                                            </v-btn>-->

                      </v-toolbar>
                    </v-card>

                  </v-col>

                </v-col>


              </v-row>

            </div>
          </div>

          <div class="col-md-12" v-if="loading">
            <v-skeleton-loader v-bind="attrs" type="table"></v-skeleton-loader>
          </div>
          <div class="col-12" v-if="users.length > 0 && !loading">

            <table class="table table-stripe">
              <thead>
                <th>
                  <v-checkbox class="id-card__checkbox ml-3" @change="selectAll()" v-model="checkAll"></v-checkbox>
                </th>
                <th>Student</th>
                <th v-if="search.id_type == 'printed' || search.id_type == 'ready'">Print status</th>
                <th>Program</th>
                <th>Semester/year</th>
                <th>Class</th>
                <!--                                    <th>Action</th>-->
              </thead>
              <tbody>
                <tr v-for="(user, index) of users" :key="index">
                  <td>
                    <v-checkbox v-model="selected" :value="user"></v-checkbox>
                  </td>
                  <td>
                    <router-link :to="{ name: 'students-summary', params: { id: user.student_id, type: 'student' } }">
                      <a href="#" class="navi-link">
                        {{ user.user_full_name ? user.user_full_name : '' }}
                      </a>

                      <span class="text-secondary">
                        <br />
                        <b> CRN No.: </b>{{ user.crn }}<br />
                        <b v-if="user.setting.midas_id">External ID:</b> {{ user.setting.midas_id }} <br
                          v-if="user.setting.midas_id">
                        <b v-if="user.setting.symbol_no">Symbol No.:</b> {{ user.setting.symbol_no ?
                          user.setting.symbol_no
                          : 'NA' }}<br />
                      </span>

                    </router-link>
                  </td>

                  <td v-if="search.id_type == 'printed' || search.id_type == 'ready'">
                    <span class="badge badge-success" v-if="search.id_type === 'printed'">Printed</span>
                    <span class="badge badge-success" v-if="search.id_type === 'ready'">File ready</span>
                  </td>

                  <td>{{ user.setting ? user.setting.program_title : "N/A" }}</td>
                  <td> {{ user.setting ? user.setting.current_semester_title : "N/A" }}</td>
                  <td>{{ user.setting ? user.setting.current_academic_class : "N/A" }}
                  </td>

                </tr>
              </tbody>
            </table>
            <!--                                <div class="col-12 text-right" v-if="users.length">-->
            <!--                                    <b-pagination-->
            <!--                                            @input="getUsers"-->
            <!--                                            v-model="page"-->
            <!--                                            :total-rows="total"-->
            <!--                                            :per-page="perPage"-->
            <!--                                    ></b-pagination>-->
            <!--                                </div>-->

            <v-dialog v-model="dialog" max-width="600">
              <v-card>
                <v-toolbar dark>
                  <v-card-title>
                    Result certificate print settings
                  </v-card-title>

                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                      <i class="fas fa-times"></i>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="certificate.file_name" label="File name" outlined dense></v-text-field>
                      <span class="text-danger" v-if="$v.certificate.file_name.$error">This information is required</span>
                    </v-col>
<!--                    <v-col cols="12">-->
<!--                      <v-switch v-model="certificate.has_background" label="Include background" outlined dense></v-switch>-->

<!--                    </v-col>-->

                    <v-col cols="12">
                      <v-select v-model="certificate.print_id" :items="printers" item-value="id" item-text="title"
                        label="Select printer" outlined dense></v-select>
                      <span class="text-danger" v-if="$v.certificate.print_id.$error">This information is required</span>
                    </v-col>
                    <v-col cols="12" class="text-right">

                      <v-btn class="cancel-btn" depressed @click="closeDialog">Cancel
                      </v-btn>
                      <v-btn :loading="isBusy" depressed outlined @click="saveIds()"
                        class="text-white ml-2 btn btn-primary">
                        Save setting & send to prepare PDF
                      </v-btn>
                    </v-col>
                  </v-row>


                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ExamService from "@/core/services/exam/ExamService";

const examService = new ExamService();
import ResultCertificateService from "@/core/services/resultCertificate/ResultCertificateService";
import PrinterService from "@/core/services/printer/PrinterService";
import { required } from "vuelidate/lib/validators";
import { API_URL } from "@/core/config";

const resultCertificateService = new ResultCertificateService();
const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()

const printerService = new PrinterService();
export default {
  name: "users",
  components: {},
  validations: {
    search: {
      // name: {required},
      level_id: { required },
      program_id: { required },
      academic_year_id: { required },
      grade_id: { required },
      class_id: { required },
      exam_id: { required },
    },
    certificate: {
      print_id: { required },
      file_name: { required }
    }
  },
  data() {
    return {
      import_excel: false,
      dob_bs: null,
      checkAll: false,
      isLoading: false,
      attrs: false,
      loading: false,
      dialog: false,
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      excel_file: null,
      isBusy: false,
      uploadDialog: false,
      certificate: {
        printer_id: null,
        file_name: null,
      },
      studentCards: [],
      printOption: [
        {
          name: "New",
          value: 'new'
        },
        {
          name: 'Printed',
          value: 'printed'
        },
        {
          name: 'Send to Print',
          value: 'ready',
        }
      ],
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      expiryDate: new Date().toISOString().substr(0, 10),
      user: {
        photo: null,
      },
      blob: null,
      idCard: {
        expiryDate: null,
        studentIds: [],

      },
      user_type: [
        { name: 'Teacher', value: 'teacher' },
        { name: 'Student', value: 'student' },
        { name: 'Staff', value: 'staff' },
      ],
      search: {
        limit: 80,

      },
      exams: [],
      users: [],
      selected: [],
      studentList: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      printers: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      perPage: null,
      isProgramLoading: false,
      isGradeLoading: false,
      isClassLoading: false,
      isExamLoading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    if (this.$route.query.first_name) {
      this.search = this.$route.query;
    }

    this.getLevels();
    // this.getPreview();
    this.getAcademicYears();
    this.getPrinters();
    this.convertAdToBs();
  },
  methods: {
    getExams() {

      this.isBusy = true;
      this.isExamLoading = true;
      examService.paginate(this.search).then(response => {
        this.exams = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.isExamLoading = false;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.isBusy = false;
      });
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.certificate.print_id = null;
      this.certificate.file_name = null;
      this.dialog = false;
    },
    getPrinters() {
      printerService.paginate().then(response => {
        this.printers = response.data.data;
      });
    },
    getPreview() {
      resultCertificateService.printPreview().then(response => {
        this.blob = 'data:image/png;base64,' + response.data;
        // console.log(response.data)
        // console.log(this.blob)

      });
    },
    resetFilter() {
      this.search = {
        limit: 80,
      }
      this.users = [];
      // this.getUsers();


    },
    changeImage(user) {
      this.user = user;
      this.uploadDialog = true;
    },
    addStudents() {
      this.selected.forEach((student) => {
        if (this.studentList.indexOf(student) === -1) {
          this.studentList.push(student);
        }
      })

    },
    removeStudentFromList(index) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.studentList.splice(index, 1);
          }
        }
      });

    },
    saveIds() {
      this.$v.certificate.$touch();
      if (this.$v.certificate.$error) {
        setTimeout(() => {
          this.$v.certificate.$reset();
        }, 3000);
      } else {
        if (this.studentList.length > 0) {
          this.studentList.forEach((student) => {
            this.studentCards.push({
              user_id: student.student_id,
              exam_id: student.exam_id,
              has_background: this.certificate.has_background,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.certificate.print_id,
              file_name: this.certificate.file_name,
            });
          })
        } else {
          this.selected.forEach((student) => {
            this.studentCards.push({
              user_id: student.student_id,
              exam_id: student.exam_id,
              has_background: this.certificate.has_background,
              expiry_date: this.expiryDate,
              issue_date: this.date,
              issued_by: this.currentUser.id,
              printer_id: this.certificate.print_id,
              file_name: this.certificate.file_name,
            });
          })
        }
        resultCertificateService.store(this.studentCards).then((res) => {
          this.studentList = [];
          this.studentCards = [];
          this.isBusy = false;
          this.$bus.emit('checkNotification',);
          this.$snotify.success("Information updated");
          this.certificate.print_id = null;
          this.certificate.file_name = null;
          this.expiryDate = '';
          this.dialog = false;
          this.getUsers();
        })
      }


    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user);
        })

      }
    },
    updateStudent() {
      delete this.user.setting
      let fd = this.generateFd();
      this.isBusy = true;
      // delete this.user.email;
      // delete this.user.phone;
      userService
        .update(this.user.id, fd)
        .then(response => {
          this.isBusy = false;
          this.$snotify.success("Information updated");
          this.uploadDialog = false;
          this.getUsers();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    convertAdToBs() {
      let data = {
        dob: this.expiryDate,
      };
      resultCertificateService
        .convertAdToBs(data)
        .then(response => {
          this.dob_bs = response.data.dob;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
          key == "photo" &&
          this.user["photo"] &&
          this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    },
    getUsers() {
      this.$v.search.$touch()
      if (this.$v.search.$error) {
        setTimeout(() => {
          this.$v.search.$reset()
        }, 3000)
      } else {
        this.loading = true
        resultCertificateService.paginate(this.search, this.page).then(response => {
          // this.$bus.emit('toggleLoader');
          this.selected = [];
          this.checkAll = false
          this.users = response.data.data;
          this.loading = false
        });
      }
    },
    editUser(id) {
      return this.$router.push({ name: "students-update", params: { id: id } });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      this.isProgramLoading = true;
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
        this.isProgramLoading = false;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      this.isGradeLoading = true;
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
        this.isGradeLoading = false;

      });
    },
    getAcademicClasses() {
      this.isClassLoading = true;
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
        this.isClassLoading = false;
      });
    },

    manageUserRole(id) {
      this.$router.push({ name: "user-role", params: { id, id } });
    },
    importStudent() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      userService.importUser(fd).then(res => {
        this.isLoading = false
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("Information imported")
        this.getUsers();
      })
    },
  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
<style>
.id-card__checkbox {
  padding-top: 0px !important;
}

.id-card__checkbox .v-messages {
  display: none;
}

.id-card__checkbox .v-input__slot {
  margin-bottom: 0px;
  background-color: transparent !important;
}
</style>
